import styled from 'styled-components';

const RecordsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  position: absolute;
  color: #e1b058;
  margin-top: 3%;

  @media (max-width: 1024px) {
    border: 3px solid #e1b058;
    border-radius: 10px;
    padding: 5%;
    background: #25132e;
  }
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
  margin-top: 5%;
`;

const TableHeader = styled.div`
  display: flex;
  width: 100%;
  height: max-content;
  justify-content: space-between;
  font-size: 100%;
  background: linear-gradient(180deg, #392b40 0%, transparent 100%);

  @media (max-width: 560px) {
    font-size: 24px;
  }
  @media (max-width: 430px) {
    font-size: 14px;
  }

  &:last-of-type {
    border: none;
  }
`;

const TableHeaderCell = styled.div`
  height: max-content;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:first-of-type {
    border-right: solid 2px #554b63;
  }
  &:nth-of-type(2) {
    border-right: solid 2px #554b63;
  }
`;

const FooterCell = styled.div`
  color: #ffffff;
  height: 50px;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #392b40 0%, transparent 100%);

  &:first-of-type {
    border-right: solid 2px #554b63;
  }
  &:nth-of-type(2) {
    border-right: solid 2px #554b63;
  }
`;

export const Title = styled.h2`
  font-weight: bold;
  font-size: 50px;
  line-height: 1;
  text-align: center;
  position: relative;
  padding: 10px 0 30px 0;
  background: linear-gradient(#b8a772 33.25%, #eaeaea 46.88%, #b8a772 67.41%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #b8a772;
  max-height: 10%;
`;

export const Record = styled.span`
  padding: 2.4% 0;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  &::after {
    content: '';
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(
        270deg,
        #370b50 0%,
        rgba(182, 165, 191, 0) 28.65%,
        #fff 46.83%,
        rgba(186, 171, 195, 0) 68.23%,
        #370b50 100%
      ),
      #b8a772;
  }
`;

export const PollBtn = styled.button`
  position: relative;
  background: radial-gradient(
      66.6% 39.29% at 50% 50%,
      #fff 0%,
      rgba(255, 255, 255, 0) 74.48%
    ),
    #b8a772;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  color: #360a4e;
  overflow: hidden;
  padding: 24px 40px;
  outline: inherit;
  border-radius: 6px;
  font-family: 'Raleway';
`;

export {
  RecordsContainer,
  TableHeaderCell,
  TableHeader,
  TableContainer,
  FooterCell,
};
