import React from 'react';
import {withStyles} from '@material-ui/core/styles';

import Checkbox from '@material-ui/core/Checkbox';

const CustomCheckbox = withStyles({
  root: {
    color: '#b8a772',
    '&$checked': {
      color: '#b8a772',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default CustomCheckbox;
