import React from 'react';
import Header from 'components/Header';
import { MainWrapper } from 'components/Main/styles';
import { Box } from '@material-ui/core';
import GamePackages from 'components/GamePackages';
import Games from 'components/Games';
import Jackpots from 'components/Jackpots';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from 'redux/authReducer';
import Oferta from 'components/Oferta';
import { useShowBlock } from 'hooks/useShowBlock';
import Rules from 'components/Rules';
import Records from 'components/Records';
import { gamesSelector, getGames } from 'redux/gamesReducer';
import UserPoints from '../Points';
const Main = () => {
  const dispatch = useDispatch();
  const { authInfo: { info } } = useSelector(authSelector);

  const { games } = useSelector(gamesSelector);

  const getAllGames = (games) => {
    let all = [];
    games.forEach((item) => {
      all = [...all, ...item.games];
    });
    return all;
  };

  React.useEffect(() => {
    if (!!info) {
      dispatch(getGames());
    }
  }, [dispatch, info]);
  const [carouselValue, setCarouselValue] = React.useState(0);
  //const localSelectedProvider = localStorage.getItem('selected_provider');
  const [selectedProvider, setSelectedProvider] = React.useState(null);

  React.useEffect(() => {
    localStorage.setItem('selected_provider', selectedProvider);
  }, [selectedProvider]);

  const jackpots = info && info.jackpots;
  const balance = info && info.balance;
  const denomination = info && info.denomination;
  const denominations = info && info.denominations;

  const {
    showBlock: showOferta,
    isBlockShown: isOfertaShown,
    hideBlock: hideOferta,
  } = useShowBlock();

  const {
    showBlock: showRules,
    isBlockShown: isRulesShown,
    hideBlock: hideRules,
  } = useShowBlock();

  const {
    showBlock: showRecords,
    isBlockShown: isRecordsShown,
    hideBlock: hideRecords,
  } = useShowBlock();

  return (
    <MainWrapper>
      <Header
        balance={balance / denomination}
        denomination={denomination}
        denominations={denominations}
        showOferta={showOferta}
        showRules={showRules}
        polls
      />
      <Box display="flex" style={{ height: '100%' }}>
        <GamePackages
          games={games}
          setSelectedProvider={setSelectedProvider}
          selectedProvider={selectedProvider}
          setCarouselValue={setCarouselValue}
        />
        <Games
          games={
            !selectedProvider
              ? getAllGames(games)
              : games.find((i) => i.package.id === selectedProvider)?.games
          }
          carouselValue={carouselValue}
          setCarouselValue={setCarouselValue}
          showRules={showRules}
          showRecords={showRecords}
        />
        <Box style={{ width: '20%' }} display="flex" flexDirection="column">
          <UserPoints />
          <Jackpots jackpots={jackpots} />
        </Box>
      </Box>
      {isOfertaShown && <Oferta hideOferta={hideOferta} />}
      {isRulesShown && <Rules hideRules={hideRules} />}
      {isRecordsShown && <Records hideRecords={hideRecords} />}
    </MainWrapper>
  );
};

export default Main;
