import styled from 'styled-components';
import bg from '../../assets/images/bg.png';
import { down } from 'styled-breakpoints';

export const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.65);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalWindow = styled.div`
  position: relative;
  width: 70%;
  max-height: 100%;
  border-radius: 0.5vw;
  background-image: ${`url(${bg})`};
  ${down('sm')} {
    width: 100%;
  }
`;

export const ModalBody = styled.div`
height: 100%;
    position: fixed;
    bottom: 15px;
    left: 50%;
    max-width: 590px;
    margin-left: -295px;
    padding: 25px 0;
    width: 100%;
    background: linear-gradient(180deg, #14041D 0%, #370B50 49.81%, #14041D 100%);
    border: 3px solid #B8A772;
    border-radius: 6px;
    z-index: 11;
    max-height: calc(100% - 15px);
`;

export const ModalTop = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 6%;
  border-top-left-radius: 0.5vw;
  border-top-right-radius: 0.5vw;
  background: linear-gradient(
    180deg,
    #706880 0%,
    #3a2d58 6%,
    #706880 12%,
    #706880 22%,
    #342448 23%,
    #0d0313 79%,
    #4f3c0d 80%,
    #4f3c0d 95%,
    #85693a 95%
  );
`;

export const ModalBot = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 6%;
  border-bottom-left-radius: 0.5vw;
  border-bottom-right-radius: 0.5vw;
  background: linear-gradient(
    0deg,
    #706880 0%,
    #3a2d58 6%,
    #706880 12%,
    #706880 22%,
    #342448 23%,
    #0d0313 79%,
    #4f3c0d 80%,
    #4f3c0d 95%,
    #85693a 95%
  );
  display: flex;
  align-items: center;
  justify-content: center;
`;
