import styled from 'styled-components';

export const ScrollBox = styled.div`
  width: 100%;
  max-height: ${props => `${props.height}`};
  overflow: auto;

  ::-webkit-scrollbar {
    /* chrome based */
    width: 0px; /* ширина scrollbar'a */
    background: transparent; /* опционально */
  }
  -ms-overflow-style: none;  /* IE 10+ */
    scrollbar-width: none; /* Firefox */
`;
