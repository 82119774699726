import React from 'react';
import { Wrapper, Button, ButtonSave } from './styles';
import { Box } from '@material-ui/core';

import { authSelector } from '../../redux/authReducer';

import up from '../../assets/images/up.png';
import down from '../../assets/images/down.png';
import { useSelector } from 'react-redux';

const GamesFooter = ({
  setCarouselValue,
  carouselValue,
  allGames,
  showRules,
  showRecords,
}) => {
  const {
    authInfo: { info },
  } = useSelector(authSelector);


  const isSocialPoll = info && info.is_social_poll

  const games = document.querySelector('.games')
  return (
    <Wrapper>
      <Box
        display="flex"
        justifyContent="space-around"
        alignItems="center"
        style={{ width: '100%' }}
      >
        <Button
          onClick={() => {
            games.scrollTo(0, games.scrollTop - 300)
          }}
        >
          <img src={up} alt="up"></img>
        </Button>
        {!!isSocialPoll && <ButtonSave
          disabled={!!(info?.balance === '0.00')}
          onClick={!!(info?.balance !== '0.00') ? showRecords : () => { }}
        >
          Сохранить рекорд
        </ButtonSave>}
        <Button
          onClick={() => {
            games.scrollTo(0, games.scrollTop + 300)
          }}
        >
          <img src={down} alt="down"></img>
        </Button>
      </Box>
    </Wrapper>
  );
};

export default GamesFooter;
