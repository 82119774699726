import React from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';

import Pusher from 'services/pusher';
import Polls from './components/Polls';
import Main from 'components/Main';
import { useDispatch, useSelector } from 'react-redux';
import {
  authSelector,
  getInfo,
  setInfoBalance,
  setPollSum,
} from 'redux/authReducer';
import { getCurrentPoll, pollsSelector } from 'redux/pollsReducer';
import { AUTH_API, faces, DEBUG, LOGIN_PAGE } from './settings';
import axios from 'axios';

axios.defaults.baseURL = AUTH_API;

const App = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    authInfo: { info },
    templateId,
    status,
    token,
  } = useSelector(authSelector);

  React.useEffect(() => {
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      dispatch(getInfo());
    } else {
      if (DEBUG) {
        faces.every(((url, idx) => url && (idx === 0 || idx >= 3) ? (console.log(`redirect to ${url}`), false) : true));
      } else {
        faces.every(((url, idx) => url && (idx === 0 || idx >= 3) ? (window.location.href = url, false) : true));
      }
    }
  }, [dispatch, token]);

  const { currentPoll, currentQuestion } = useSelector(pollsSelector);

  const [pusherClient, setPusherClient] = React.useState(null);

  React.useEffect(() => {
    if (!!info?.pusher && !pusherClient) {
      setPusherClient(
        new Pusher(info.pusher.key, {
          cluster: info.pusher.cluster,
          forceTLS: true,
          authEndpoint: `${AUTH_API}/auth/pusher`,
          auth: {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              Accept: 'application/json',
            },
          },
        })
      );
    }
  }, [info?.pusher, pusherClient]);

  React.useEffect(() => {
    if (info) {
      if (currentQuestion !== -1 || info.poll_sum || info.highscore) {
        navigate('/polls');
      }
    }
  }, [currentPoll, info, navigate, currentQuestion]);

  React.useEffect(() => {
    if (status === 200) {
      !currentPoll.error && dispatch(getCurrentPoll());
    }
  }, [dispatch, status]);

  React.useEffect(() => {
    if (!!pusherClient) {
      const channel = pusherClient.subscribe(
        `private-player-${(info && info.id) || 0}`
      );
      channel.bind('change-player', (data) => {
        if (data.cmd !== undefined && data.cmd === 'reload') {
          window.location.reload();
        }
        if (data.cmd !== undefined && data.cmd === 'home') {
          window.location.href = "/";
        }
        info.poll_sum > 1 && dispatch(setInfoBalance('0.00'));
        if ("pollscore" in data) {
          dispatch(setPollSum(data.pollscore));
          if (!data.pollscore) {
            navigate('/');
          }
        }
        if (data.status === 0) {
          if(DEBUG) {
            console.log(`redirect to ${LOGIN_PAGE}`);
          } else {
            window.location.href = LOGIN_PAGE;
            localStorage.removeItem('token');
          }
        }
        data.balance !== undefined && dispatch(setInfoBalance(data.balance));
      });
      return () =>
        pusherClient &&
        function () {
          channel.unbind('change-player');
        };
    }
  }, [dispatch, pusherClient]);

  React.useEffect(() => {
    if (templateId !== 2 && faces[templateId]) {
      if (DEBUG) {
        console.log(`redirect to ${faces[templateId]}`);
      } else {
        window.location.href = faces[templateId];
      }
    }
  }, [templateId]);

  return (
    <Routes>
      <Route exact path="/" element={<Main pusherClient={pusherClient} />} />
      <Route exact path="/polls" element={<Polls pusherClient={pusherClient} />} />
    </Routes>
  );
};

export default App;
