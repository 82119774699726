import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  min-height: 400px;
  background: linear-gradient(180deg, #14041d 0%, #370b50 49.81%, #14041d 100%);
  border: 3px solid #b8a772;
  border-radius: 6px;
  color: #fff;
  z-index: 1;
  width: 70%;
  margin: 0 auto;
  margin-top: 25px;
`;

const AcceptConditionsText = styled.p`
  font-family: 'Candara', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 21px;
  margin-top: 5px;
  color: #b8a772;
  @media (max-width: 900px) {
    font-size: 14px;
    flex-wrap: wrap;
  }
`;

const ConditionsLink = styled.a`
  color: #b8a772;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 5px;
`;

export const Title = styled.h3`
  font-weight: bold;
  font-size: 200%;
  font-family: 'Raleway';
  line-height: 67px;
  text-align: center;
  position: relative;
  background: linear-gradient(#b8a772 33.25%, #eaeaea 46.88%, #b8a772 67.41%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #b8a772;

  &::after {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    width: 100%;
    height: 2px;
    background: linear-gradient(
        270deg,
        #370b50 0%,
        rgba(182, 165, 191, 0) 28.65%,
        #fff 46.83%,
        rgba(186, 171, 195, 0) 68.23%,
        #370b50 100%
      ),
      #b8a772;
  }
`;

export {Container, AcceptConditionsText, ConditionsLink};
