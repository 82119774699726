import React from 'react';
import { Wrapper, Jackpot, Title } from './styles';
import brilliant from '../../assets/images/brilliant.gif';
import gold from '../../assets/images/gold.gif';
import silver from '../../assets/images/silver.gif';
import bronze from '../../assets/images/bronze.gif';

const Jackpots = ({ jackpots }) => {
  const getJackpot = (name) => {
    const jackpot = jackpots[name];

    return typeof jackpot === 'number' ? jackpot : false;
  };

  // console.log(typeof getJackpot('diamond') === 'number')
  return (
    <Wrapper>
      <Title />
      {jackpots && (
        <>
          {typeof getJackpot('diamond') === 'number' && (
            <Jackpot
              bg={brilliant}
              color="#b9f2ff"
            >
              {Math.floor(getJackpot('diamond') / 100)}
            </Jackpot>
          )}
          {typeof getJackpot('gold') === 'number' && (
            <Jackpot bg={gold} color="gold">
              {Math.floor(getJackpot('gold') / 100)}
            </Jackpot>
          )}
          {typeof getJackpot('silver') === 'number' && (
            <Jackpot
              bg={silver}
              color="silver"
            >
              {Math.floor(getJackpot('silver') / 100)}
            </Jackpot>
          )}
          {typeof getJackpot('bronze') === 'number' && (
            <Jackpot
              style={{ backgroundSize: '100% 95%' }}
              bg={bronze}
              color="#cd7f32"
            >
              {Math.floor(getJackpot('bronze') / 100)}
            </Jackpot>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default Jackpots;
