import { createSlice } from '@reduxjs/toolkit';
import { auth } from 'services';

export const slice = createSlice({
  name: 'auth',
  initialState: {
    status: '',
    token: localStorage.getItem('token'),
    templateId: null,
    loading: false,
    error: null,
    data: null,
    records: {
      items: [],
      loading: false,
      error: null,
    },
    authInfo: {
      info: null,
      loading: false,
      error: null,
    },
  },
  reducers: {
    loginIsLoading: (state) => {
      state.loading = true;
    },
    loginSuccess: (state, { payload }) => {
      state.isAuthenticated = true;
      state.loading = false;
      state.error = null;
      state.token = payload;
    },
    loginFailure: (state, { payload }) => {
      state.isAuthenticated = false;
      state.error = payload;
    },
    loginStatus: (state, { payload }) => {
      state.status = payload;
    },
    getInfoIsLoading: (state) => {
      state.authInfo.loading = true;
    },
    getInfoSuccess: (state, { payload }) => {
      state.authInfo.info = payload;
      state.authInfo.loading = false;
      state.authInfo.error = null;
    },
    getInfoFailure: (state, { payload }) => {
      if(DEBUG) {
        console.log(`redirect to ${LOGIN_PAGE}`);
      } else {
        window.location.href = LOGIN_PAGE;
      }
      state.authInfo.loading = false;
      state.authInfo.error = payload;
    },
    getRecordsIsLoading: (state) => {
      state.records.loading = true;
    },
    getRecordsSuccess: (state, { payload }) => {
      state.records.items = payload;
      state.records.loading = false;
      state.records.error = null;
    },
    getRecordsFailure: (state, { payload }) => {
      state.records.loading = false;
      state.records.error = payload;
    },
    setInfoBalance: (state, { payload }) => {
      state.authInfo.info.balance = payload;
    },
    setPollSum: (state, { payload }) => {
      state.authInfo.info.poll_sum = payload;
    },
    setInfoDenom: (state, { payload }) => {
      state.authInfo.info.denomination = payload;
    },
    setTemplateId: (state, { payload }) => {
      state.templateId = payload;
    },
    setHighscore: (state, { payload }) => {
      state.authInfo.info.highscore = payload;
    },
    setToken: (state, { payload }) => {
      state.token = payload;
    },
  },
});

export function getRecords() {
  return async (dispatch) => {
    dispatch(getRecordsIsLoading());

    try {
      const response = await auth.fetchRecords();
      dispatch(getRecordsSuccess(response.data));
    } catch (error) {
      dispatch(getRecordsFailure(error.message));
    }
  };
}
export function getInfo() {
  return async (dispatch) => {
    dispatch(getInfoIsLoading());
    auth
      .fetchInfo()
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(getInfoSuccess(res.data.data));
          dispatch(setTemplateId(res.data.data.hall_template))
          dispatch(loginStatus(res.data.code));
        } else {
          dispatch(getInfoFailure(res.data.data));
          dispatch(loginStatus(res.data.code));
          if (res.data.code === 401) {
            localStorage.removeItem("token");
            setToken(null);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(getInfoFailure(err));
        dispatch(loginStatus(400));
      });
  };
}

export function updateInfo(f = null) {
  return async (dispatch) => {
    dispatch(getInfoIsLoading());
    auth.fetchInfo().then((res) => {
      if (res.data.code === 200) {
        if (!res.data.data.poll_sum) {
          dispatch(setPollSum(0));
          f && f();
        }
      }
    });
  };
}

// export function signUp(data) {
//   return async (dispatch) => {
//     dispatch(loginIsLoading());

//     auth.login(data).then((res) => {
//       console.log(res.data);
//       if (res.data.code === 200) {
//         dispatch(loginStatus(res.data.code));
//         dispatch(loginSuccess(res.data.data));
//         localStorage.setItem('token', res.data.data.token);
//       } else if (res.data.code !== 422) {
//         dispatch(loginFailure(res.data.data));
//       }
//     });
//   };
// }

export function handlerCollect() {
  return async (dispatch) => {
    auth.collect().then((res) => {
      if (res.status === 'ok') {
        dispatch(setInfoBalance('0.00'));
      }
    });
  };
}

export function handlerRemoteIn(credits) {
  return async (dispatch) => {
    auth.remoteIn(credits).then((res) => {
      if (res.status === 'ok') {
        dispatch(setInfoBalance(res.balance));
        dispatch(setPollSum('0.00'));
      }
    });
  };
}

export const {
  login,
  getInfoFailure,
  getInfoIsLoading,
  getInfoSuccess,
  loginFailure,
  loginSuccess,
  loginIsLoading,
  loginStatus,
  getRecordsFailure,
  getRecordsIsLoading,
  getRecordsSuccess,
  setInfoBalance,
  setPollSum,
  setInfoDenom,
  setTemplateId,
  setHighscore,
  setToken
} = slice.actions;

export const authSelector = (state) => state.auth;

export default slice.reducer;
