import React from 'react';
import {
  GoldTextButton,
  HeaderWrapper,
  Logo,
  ButtonsWrapper,
  GoldBgButton,
} from './styles';

import logo from '../../assets/images/logo.png';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { authSelector } from 'redux/authReducer';

const Header = ({
  balance,
  denomination,
  denominations,
  showOferta,
  showRules,
}) => {
  const { authInfo: { info } } = useSelector(authSelector)

  const isSocialPoll = info && info.is_social_poll
  return (
    <HeaderWrapper>
      <div className="bg-color-purpl">
        <Logo>
          <img src={logo} alt="logo" />
        </Logo>
        <ButtonsWrapper>
          <GoldTextButton onClick={showRules}>Правила</GoldTextButton>
          <GoldTextButton onClick={showOferta}>Оферта</GoldTextButton>
          {!!isSocialPoll && <Link to='/polls'><GoldBgButton>Пройти опрос</GoldBgButton></Link>}
        </ButtonsWrapper>
      </div>
    </HeaderWrapper>
  );
};

export default Header;
