import styled from 'styled-components';

export const MainWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  max-height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
        'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
        'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

  .select-provider {
    position: relative;
    width: max-content;
    height: max-content;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }

  .select-provider > span {
    color: #7a0000;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 55%;
    left: 50%;
    width: max-content;
    font-size: 3.3vh;
  }

  .select-provider > img {
    width: 120%;
  }

  .wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  @media (max-width: 900px) {
    .select-provider > span {
      font-size: 10px;
      width: 100px;
    }
    .select-provider > img {
      width: 200px;
    }
  }
`;

export const Message = styled.span`
  text-align: center;
  width: 100%;
  font-size: 5vh;
  line-height: 8vh;
  text-transform: uppercase;
  background: linear-gradient(
    180deg,
    rgba(250, 242, 161, 1) 0%,
    rgba(207, 127, 34, 1) 100%
  );
  -webkit-text-fill-color: transparent;
  color: #0b2349;
  display: table;
  filter: drop-shadow(0 6px 4px #0000007d);
`;

export const Heading = styled.header`
  height: max-content;
  border-bottom: 2px solid #635d73;
  box-sizing: border-box;
  color: #005792;
  z-index: 15;
  position: relative;
  .wrapper {
    padding: 5vh 0%;
    width: 70%;
    margin: auto;
    display: flex;
    justify-content: center;
    color: #fff;
    font-size: 32px;
  }
`;

export const Lines = styled.div`
  border-top: 2px solid #635d73;
  border-bottom: 2px solid #c4a260;
  background: linear-gradient(
    180deg,
    #1d1525 8%,
    #635d73 0,
    #635d73 25%,
    #2c1a41 0,
    #110417 79%,
    #c79536 0,
    #c79536
  );
  position: relative;
  height: 14.2%;
  box-shadow: 0 2px 4px #000;
`;

export const Footer = styled.footer`
  opacity: 0.7;
  margin-top: auto;
  border-top: 2px solid #635d73;
  display: flex;
  justify-content: space-between;
  padding: 0 15%;
  margin-top: 5%;
`;
