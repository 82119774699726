import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { MainWrapper } from './styles';


import Poll from '../Poll';
import { useForm } from 'hooks/useForm';
import StartPoll from '../StartPoll';
import { authSelector } from 'redux/authReducer';

import { pollsSelector, getNewPoll } from 'redux/pollsReducer';
import { getInfo } from '../../redux/authReducer';
import { getCurrentPoll } from '../../redux/pollsReducer';
import { Box } from '@material-ui/core';
import Header from 'components/Header';
const Polls = ({
  showRecords,
  isRecordsShown,
  isRulesShown,
  showPoll,
  isPollShown,
  hidePoll,
  isOfertaShown,
  showCollect,
}) => {
  const dispatch = useDispatch();
  const { form, handleInputChange } = useForm({ isAccepted: false });

  const {
    authInfo: { info },
    // status,
  } = useSelector(authSelector);

  React.useEffect(() => {
    dispatch(getInfo());
    dispatch(getCurrentPoll());
  }, [dispatch]);

  const [isPollStarted, setIsPollStarted] = React.useState(false);
  const {
    currentPoll: { data },
    currentQuestion,
  } = useSelector(pollsSelector);

  React.useEffect(() => {
    if (info && info.poll_sum) {
      setIsPollStarted(true);
    }
    // eslint-disable-next-line
  }, [info && info.poll_sum]);


  React.useEffect(() => {
    if (currentQuestion !== -1 && data && data.questions) {
      setIsPollStarted(true);
    }
    // eslint-disable-next-line
  }, [currentQuestion, data && data.questions]);


  // const hotkeys = info && info.hotkeys;
  // const handleKeyPress = (e) => {
  //   const {code} = e;
  //   const {save_record, pass_poll, collect} = hotkeys;
  //   if (!isRulesShown && !isOfertaShown && !isPollShown && !isRecordsShown) {
  //     //save record
  //     if (code === save_record[0]) {
  //       setIsPollStarted(false);
  //       showRecords();
  //     }
  //     //pass poll
  //     if (code === pass_poll[0]) {
  //       setIsPollStarted(false);
  //       showPoll();
  //     }
  //     //collect
  //     if (code === collect[0]) {
  //       info && info.balance > 0 && showCollect();
  //     }
  //   }
  // };

  // React.useEffect(() => {
  //   if (hotkeys) {
  //     document.addEventListener('keydown', handleKeyPress);

  //     return () => {
  //       document.removeEventListener('keydown', handleKeyPress);
  //     };
  //   }
  //   // eslint-disable-next-line
  // }, [info, isRulesShown, isOfertaShown, isPollShown, isRecordsShown]);

  //const jackpots = info && info.jackpots;
  const balance = info && info.balance;
  const denomination = info && info.denomination;
  const denominations = info && info.denominations;

  return (
    <MainWrapper>
      <Header
        balance={balance / denomination}
        denomination={denomination}
        denominations={denominations}
        showOferta={() => { }}
      />
      {info && (
        <Box>
          {isPollStarted ? (
            <Poll hidePoll={hidePoll} />
          ) : (
            <StartPoll
              startPoll={() => {
                dispatch(getNewPoll());
                setIsPollStarted(true);
              }}
              checked={form.isAccepted}
              onChange={handleInputChange}
              name="isAccepted"
            />
          )}
        </Box>
      )}
    </MainWrapper>
  );
};

export default Polls;
