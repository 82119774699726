import React from 'react';
import { Overlay } from 'components/Modal/styles';

import { Wrapper, Title, Text } from './styles';
import { GoldTextButton } from 'components/Header/styles';
import { ScrollBox } from 'components/Common/ScrollBox/styles';
import { Box } from '@material-ui/core';

const Rules = ({ hideRules }) => {
  return (
    <Overlay>
      <Wrapper>
        <Title>Правила использования развлекательного терминала</Title>
        <ScrollBox height={'73vh'}>
          <Text>
            Развлекательный терминал - аппаратно-программный комплекс,
            обеспечивающий приём платежей за оказание услуг развлекаткльного
            характера от пользователя в режиме самообслуживания.
          </Text>
          <Text>
            Развлекательный терминал является автономным средством по оказанию
            услуг и приёму платежей в автоматическом режиме.
          </Text>
          <Text>
            Развлекательный терминал предназначен для: <br />
            приёма платежей за услуги развлекательного характера <br />
            пополнения очков развлекательной системы.
          </Text>
          <Text>
            С помощью экранного меню развлекаткльного терминала пользователь
            пополняет быланс лчков путём принятия публичного договора оферты и
            внесения денежных средств и выбирает услугу, которую он хотел бы
            получитью
          </Text>
          <Text>
            Следуя инструкциям, выведенным на экране, осуществляется процесс
            получения развлекательной услуги.
          </Text>
          <Text>
            Развлекательные услуги не содержат в себе компонента выигрыша или
            получения каких-либо материальных выгод взамен использования услуг,
            предоставляемых терминалом.
          </Text>
          <Text>
            В качестве мотивирующего результата оказанной услуги каждому
            пользователю системы присваивается результат, отображающий уровень
            его мастерства и сноровки при достижении целей развлекательного
            процесса, предоставляемого терминалом
          </Text>
        </ScrollBox>
        <Box display="flex" justifyContent="center">
          <GoldTextButton style={{ marginTop: '13px' }} onClick={hideRules}>
            Принять условия
          </GoldTextButton>
        </Box>
      </Wrapper>
    </Overlay>
  );
};

export default Rules;
