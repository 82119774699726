import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import button from 'assets/images/violet-button.png';
import PollOffer from 'components/PollOffer';

import { Container, AcceptConditionsText, ConditionsLink, Title } from './styles';

import CustomCheckbox from 'components/Common/Checkbox';
import { FlexBox } from 'components/Common/FlexBox';
import { SubmitAnswer } from '../Poll/styles';
import { Box } from '@material-ui/core';

const StartPoll = ({ startPoll, ...props }) => {
  const [isChecked, setIsChecked] = React.useState(false);
  const [isOfferShown, setOfferVisibility] = React.useState(false);

  // const handleKeyPress = e => {
  //   const { agree, disagree, start_poll } = info && info.hotkeys
  //   const { code } = e
  //     if(code === agree[0]) {
  //       setIsChecked(!isChecked)
  //     } else if(code === disagree[0]) {
  //       setIsChecked(!isChecked)
  //     } else if(code === start_poll[0] && isChecked) {
  //       startPoll()
  //     }
  // }

  // React.useEffect(() => {
  //   if(info && info.hotkeys) {
  //     document.addEventListener("keydown", handleKeyPress);
  //   }
  //   return () => {
  //     document.removeEventListener("keydown", handleKeyPress);
  //   };
  //   // eslint-disable-next-line
  // }, [info, isChecked]);

  return isOfferShown ? <PollOffer hide={() => setOfferVisibility(false)} /> : <Container>
    <Title>Социальные опросы</Title>
    <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column' style={{ margin: 'auto' }}>
      <SubmitAnswer img={button} onClick={() => isChecked && startPoll()} disabled={!isChecked} margin="0 0 5% 0" >
        Начать опрос
      </SubmitAnswer>
      <FlexBox alignItems="center">
        <FormControlLabel
          control={<CustomCheckbox checked={isChecked} onChange={() => setIsChecked(!isChecked)} name={props.name} />}
          label={
            <AcceptConditionsText>
              Я соглашаюсь с условиями{'  '}
              <ConditionsLink onClick={(e) => setOfferVisibility(true)}> {' '} публичной оферты </ConditionsLink>{' '}
            </AcceptConditionsText>
          }
        />
      </FlexBox>
    </Box>
  </Container>;
};

export default StartPoll;
