import styled from 'styled-components';

export const Wrapper = styled.footer`
  width: 100%;
  height: 12vh;
  background: linear-gradient(180deg, #14041d 0%, #370b50 52.03%, #14041d 54.4%),
    #14041d;
  box-shadow: 0px -12px 25px #0a0410;
  text-align: center;
  z-index: 10;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  justify-content: center;
`;

export const Button = styled.button`
  padding: 0;
  width: 70px;
  height: 70px;
  position: relative;
  border: 1px solid #b8a772;
  border-radius: 6px;
  background-color: #370b50;
`;

export const ButtonSave = styled.button`
    position: relative;
    background: radial-gradient(66.6% 39.29% at 50% 50%, #fff 0%, rgba(255,255,255,0) 74.48%),#B8A772;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    box-shadow: 0 4px 4px rgba(0,0,0,0.25);
    color: #360A4E;
    overflow: hidden;
    padding: 24px 25px;
    border-radius: 6px;
    font-family: 'Raleway';
`;
