import styled from 'styled-components';
import {down, up} from 'styled-breakpoints';
import banner from '../../assets/images/banner.png';

export const HeaderWrapper = styled.header`
  width: 100%;
  height: 20vh;
  background: #000;
  position: relative;

  ::after {
    content: '';
    position: absolute;
    bottom: 0;
    height: 1px;
    width: 100%;
    background: linear-gradient(90deg, #0a0410 0%, rgba(10, 4, 16, 0) 21.3%),
      linear-gradient(270deg, #0a0410 0%, rgba(10, 4, 16, 0) 20.77%),
      linear-gradient(
        270deg,
        rgba(255, 255, 255, 0) 27.47%,
        #fff 48.58%,
        rgba(255, 255, 255, 0) 69.27%
      ),
      #b8a772;
  }

  .bg-color-purpl {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    background-color: '#14041';
    background: no-repeat center/68% url(${banner});
    background-size: 65%;
    object-fit: cover;
  }

  ${down('md')} {
    padding: 0 2%;
  }

  ${down('sm')} {
    .bg-color-purpl {
      background: no-repeat center/100% url(${banner});
    }
  }
`;

export const Logo = styled.div`
  max-width: 284px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 17vh;
    height: 17vh;
  }

  ${down('md')} {
    width: max-content;
    img {
      width: 15vw;
      height: 15vw;
    }
  }
`;

export const MainBanner = styled.div`
  max-width: 995px;
  width: 100%;

  img {
    width: 100%;
  }
`;

export const ButtonsWrapper = styled.div`
  max-width: 284px;
  width: 100%;
  font-family: 'Raleway';
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  ${down('sm')} {
    flex-direction: column;
    width: max-content;
  }
`;

export const GoldTextButton = styled.button`
  font-size: 100%;
  text-align: center;
  border: 1px solid #b8a772;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  background: #000;
  color: #b8a772;
  padding: 10px 25px;
  margin: 0 10px;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  border-radius: 6px;
  ${down('sm')} {
    margin: 0;
    padding: 1% 3%;
    max-width: 180px;
  }
`;

export const GoldBgButton = styled.button`
  margin-top: 26px;
  position: relative;
  background: radial-gradient(
      66.6% 39.29% at 50% 50%,
      #fff 0%,
      rgba(255, 255, 255, 0) 74.48%
    ),
    #b8a772;
  font-weight: bold;
  font-size: 20px;
  font-family: 'Raleway';
  line-height: 23px;
  text-align: center;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  color: #360a4e;
  overflow: hidden;
  padding: 10px 25px;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  border-radius: 6px;

  ${down('sm')} {
    margin: 0;
    padding: 1% 3%;
    max-width: 180px;
  }
`;

export const Button = styled.button`
  cursor: pointer;
  background: transparent ${(props) => (props.img ? `url(${props.img})` : '')};
  background-size: 100% 100%;
  background-position: center center;
  margin: 0 0;
  font-family: 'Raleway';
  width: 165px;
  height: 4vh;
  font-size: 70%;
  font-weight: normal;
  font-style: normal;
  color: ${(props) => (props.disabled ? '#616161' : '#fff')};
  border: none;
  ${(props) =>
    !props.disabled &&
    `&:hover {
    filter: brightness(1.6)
  }`}

  ${up('lg')} {
    width: 25.57vh;
    height: 5vh;
    font-size: 1.8vmin;
  }

  ${up('xl')} {
    font-size: 100%;
    width: 220px;
    height: 5vh;
  }
`;
