import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  left: 50%;
  max-width: 900px;
  margin-left: -450px;
  padding: 40px 70px;
  background: linear-gradient(180deg, #14041d 0%, #370b50 49.81%, #14041d 100%);
  border: 3px solid #b8a772;
  border-radius: 6px;
  z-index: 11;
  max-height: calc(100% - 15px);

  span {
    font-size: 16px;
    line-height: 29px;
    color: #9f9f9f;
  }
`;
export const Title = styled.h3`
  font-weight: bold;
  font-size: 150%;
  font-family: 'Raleway';
  line-height: 35px;
  text-align: center;
  color: #b8a772;
  margin: 0 auto;
`;
