import { API } from 'settings';
import axios from 'axios';

const fetchRecords = async (gameId) => {
  const URI = `${API}/v1/game-results?f[game_id]=${gameId}&per-page=2&page=1`;
  const query = {
    method: 'GET',
    // headers: {
    //   'Content-Type': 'application/json',
    // },
  };

  const response = await fetch(URI, query);
  const json = await response.json();
  return json;
};

const saveRecord = async () => {
  const URI = `/v1/save-highscore`;
  return await axios.get(URI);
};

export default {
  fetchRecords,
  saveRecord,
};
