import styled from 'styled-components';

const PollContainer = styled.div`
  justify-content: center;
  color: #fff;
  background: linear-gradient(180deg, #14041d 0%, #370b50 49.81%, #14041d 100%);
  border: 3px solid #b8a772;
  border-radius: 6px;
  position: relative;
  z-index: 1;
  width: 70%;
  margin: 0 auto;
  margin-top: 25px;

  .selected {
    color: grey;
    border: 1px solid #c79536;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .fadeIn {
    animation: fadeIn 1s ease-in-out;
  }

  .question-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`;

const QuestionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;

const AnswerButton = styled.button`
  cursor: pointer;
  width: 100%;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  border: 1px solid #b8a772;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  background: transparent;
  color: #b8a772;
  border-radius: 6px;
  font-family: 'Raleway';
  padding: 3% 6%;
`;

export const SubmitAnswer = styled.button`
  position: relative;
  background: radial-gradient(
      66.6% 39.29% at 50% 50%,
      #fff 0%,
      rgba(255, 255, 255, 0) 74.48%
    ),
    #b8a772;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  color: #360a4e;
  overflow: hidden;
  border-radius: 6px;
  font-family: Raleway;
  padding: 10px 25px;
`;
const Sum = styled.div`
  margin: 15px;
  border: 1px solid #b8a772;
  padding: 5px 25px;
  width: max-content;
  background: #370b50;
  box-shadow: inset 3px 3px 4px rgba(0, 0, 0, 0.3),
    inset -3px -3px 4px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  font-size: 150%;
  font-family: 'Raleway';
  line-height: 45px;
  text-align: center;
  background: linear-gradient(#b8a772 33.25%, #eaeaea 46.88%, #b8a772 67.41%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #b8a772;
`;

const Subheading = styled.h4`
  font-size: 20px;
  font-weight: 300;
  font-family: 'Raleway';
  line-height: 23px;
  text-align: center;
  color: #b8a772;
  padding: 2% 0;
  width: 80%;
  line-height: 37px;
`;

export const PollsCount = styled.span`
  font-weight: bold;
  font-size: 200%;
  font-family: 'Raleway';
  margin-top: 10px;
  padding: 10px;
  background: linear-gradient(#b8a772 33.25%, #eaeaea 46.88%, #b8a772 67.41%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const Thanks = styled.p`
  font-size: 100%;
  font-family: 'Raleway';
  font-weight: normal;
  color: #B8A772;
  margin: auto;
  text-align: center;
`;

export {PollContainer, AnswerButton, Sum, Subheading, QuestionContainer};
