import styled from 'styled-components';
import {down} from 'styled-breakpoints';

export const Wrapper = styled.div`
  background: linear-gradient(180deg, #1b0a24, #010105);
  font-size: 16pt;
  font-style: oblique;
  color: white;
  margin-right: auto;
  margin-left: 0px;
  width: 20%;
  float: left;
  background-color: rgba(1, 1, 1, 0.3);
  height: max-content;
  font-family: 'Colus';
  font-weight: normal;
  font-style: normal;

  .active {
    background: linear-gradient(
        180deg,
        #14041d 0%,
        #370b50 52.03%,
        #14041d 54.4%
      ),
      #14041d;
  }

  ${down('sm')} {
    display: none;
  }
`;

export const Package = styled.label`
  cursor: pointer;
  position: relative;
  height: 54px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 2px;
  font-family: Raleway;
  font-weight: normal;
  font-size: 120%;
  color: #b8a772;
  width: 100%;



  &:hover {
    background: linear-gradient(
        180deg,
        #14041d 0%,
        #370b50 52.03%,
        #14041d 54.4%
      ),
      #14041d;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, #0a0410 0%, rgba(10, 4, 16, 0) 21.3%),
      linear-gradient(270deg, #0a0410 0%, rgba(10, 4, 16, 0) 20.77%),
      linear-gradient(
        270deg,
        rgba(255, 255, 255, 0) 27.47%,
        #fff 48.58%,
        rgba(255, 255, 255, 0) 69.27%
      ),
      #b8a772;
  }
`;
