import styled from 'styled-components';
import jackpotTitle from '../../assets/images/jackpot.gif';

export const Wrapper = styled.div`
  margin-right: 0em;
  margin-left: auto;
  display: inline-block;
  max-width: 100%;
  width: 100%;
  color: white;
  height: max-content;
  background: linear-gradient(180deg, #14041d 0%, #370b50 50%, #14041d 100%),
    #370b50;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, #0a0410 0%, rgba(10, 4, 16, 0) 21.3%),
      linear-gradient(270deg, #0a0410 0%, rgba(10, 4, 16, 0) 20.77%),
      linear-gradient(
        270deg,
        rgba(255, 255, 255, 0) 27.47%,
        #fff 48.58%,
        rgba(255, 255, 255, 0) 69.27%
      ),
      #b8a772;
  }
`;

export const Jackpot = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 110%;
  font-weight: bold;
  /* margin: 10% 0; */
  line-height: 2.4;
  color: #b8a772;
  background: ${(props) => `url(${props.bg})`} no-repeat center / cover;
  background-size: 100% 70%;
  padding: 0 10px;
  font-family: Epson;
`;

export const Title = styled.div`
  background: url(${jackpotTitle}) no-repeat center / cover;
  height: 44px;
  width: 100%;
`;
