import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { PollsCount } from './styles';
import { PollContainer, Sum, AnswerButton, Subheading, Thanks } from './styles';
import { updateInfo } from '../../redux/authReducer';
import { pollsSelector, answerQuestion, finishPoll, setCurrentQuestion } from 'redux/pollsReducer';
import { authSelector, setHighscore, setPollSum } from 'redux/authReducer';
import { Box, Grid } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { prettyNum } from 'utils/helpers';
import { Title } from 'components/StartPoll/styles';

const capitalizeFirstWord = (str) => str.charAt(0).toUpperCase() + str.slice(1);

const Poll = ({ hidePoll }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    currentPoll: { data, error, loading },
    currentQuestion,
    questionAnsweredLoading,
  } = useSelector(pollsSelector);

  const {
    authInfo: { info },
  } = useSelector(authSelector);
  const [isPollFinished, setIsPollFinished] = React.useState(false);

  const [endPollSum, setEndPollSum] = React.useState(0);

  const [answerId,] = React.useState(null);

  const answerTheQuestion = (id) => {
    const questionId = data.questions[currentQuestion].id;
    const answerId = id;
    dispatch(answerQuestion({ questionId, answerId }));
  };

  const finishThePoll = React.useCallback(async () => {
    await dispatch(finishPoll());
    setIsPollFinished(true);
  }, [dispatch]);

  const postAnswer = (questId) => {
    if (!questionAnsweredLoading) {
      if (currentQuestion === 9) {
        setEndPollSum(pollSum());
        answerTheQuestion(data.questions[currentQuestion].answers[questId].id);
      } else {
        answerTheQuestion(data.questions[currentQuestion].answers[questId].id);
      }
    }
  };

  React.useEffect(() => {
    if (info && info.poll_sum > 0) setIsPollFinished(true);
  }, [info, info.poll_sum]);

  const InfoBalance = info && info.balance;
  const PollSum = info && info.poll_sum;
  React.useEffect(() => {
    if (error && isPollFinished && PollSum === 0) {
      setIsPollFinished(false);
    }
  }, [InfoBalance, PollSum, hidePoll, isPollFinished, error]);

  const pollSum = React.useCallback(() => {
    let sum = 0;
    data &&
      data.questions.forEach((item, id) => {
        if (id <= currentQuestion) {
          sum += item.sum;
        }
      });

    return sum.toFixed(2);
  }, [data, currentQuestion]);

  React.useEffect(() => {
    if (currentQuestion === 10) {
      dispatch(setPollSum(pollSum()));
      dispatch(setCurrentQuestion(-1));
      dispatch(setHighscore(0));
      !loading && finishThePoll();
    }
  }, [dispatch, currentQuestion, finishThePoll, pollSum, loading]);

  React.useEffect(() => {
    const interval = setInterval(() => isPollFinished && dispatch(updateInfo(() => navigate('/'))), 40000);
    return () => clearInterval(interval);
  }, [isPollFinished, dispatch, navigate]);

  // //buttons control
  // const handleKeyPress = (e) => {
  //   const {first_answer, second_answer, third_answer} = info && info.hotkeys;
  //   const {code} = e;
  //   const getQuestionId = (btnId) => {
  //     let answerId;
  //     data &&
  //       data.questions[currentQuestion].answers.forEach((item, id) => {
  //         if (id === btnId) {
  //           answerId = item.id;
  //         }
  //       });
  //     return answerId;
  //   };

  //   if (
  //     !questionAnsweredLoading &&
  //     data &&
  //     data.questions &&
  //     currentQuestion < 10 &&
  //     currentQuestion !== -1
  //   ) {
  //     if (code === first_answer[0]) {
  //       postAnswer(getQuestionId(0));
  //     } else if (code === second_answer[0]) {
  //       postAnswer(getQuestionId(1));
  //     } else if (code === third_answer[0]) {
  //       postAnswer(getQuestionId(2));
  //     }
  //   }
  // };

  // React.useEffect(() => {
  //   if (info && info.hotkeys) {
  //     document.addEventListener('keydown', handleKeyPress);
  //   }
  //   return () => {
  //     document.removeEventListener('keydown', handleKeyPress);
  //   };
  //   // eslint-disable-next-line
  // }, [data, info, currentQuestion, questionAnsweredLoading]);

  if (isPollFinished) {
    return (
      <PollContainer>
        <Title>Социальные опросы</Title>
        <div className="question-wrapper fadeIn" style={{ margin: '15vh 0' }}>
          <Thanks>
            Благодарим Вас за участие в социальном исследовании! Теперь Вы
            можете забрать заработанные деньги.
          </Thanks>
          <Sum>
            <span>Сумма: </span>
            {endPollSum ? prettyNum(endPollSum) : info && prettyNum(info.poll_sum)}
          </Sum>
        </div>
      </PollContainer>
    );
  }

  // if (error) {
  //   return (
  //     <PollContainer>
  //       <Box
  //         display="flex"
  //         justifyContent="center"
  //         alignItems="center"
  //         flexDirection="column"
  //       >
  //         <h4>Произошла ошибка, приносим свои извенения...</h4>
  //         <SubmitAnswer
  //           img={button}
  //           onClick={() => {
  //             navigate('/')
  //           }}
  //         >
  //           НАЗАД
  //         </SubmitAnswer>
  //       </Box>
  //     </PollContainer>
  //   );
  // }

  return (
    <>
      {!loading ? (
        <PollContainer>
          <Title>Социальные опросы</Title>

          {data && (
            <>
              {data &&
                data.questions.map((question, index) => (
                  <React.Fragment key={question.id}>
                    {index === currentQuestion && (
                      <div className="question-wrapper fadeIn">
                        <PollsCount>{currentQuestion + 1}/10</PollsCount>
                        <Subheading>{question.question.text}</Subheading>
                        <Box style={{ maxWidth: '270px' }}>
                          <Grid container spacing={1}>
                            {question.answers.map((quest, id) => (
                              <Grid key={id} item xs={id === 2 ? 12 : 6}>
                                <AnswerButton className={id === answerId ? 'selected' : ''} key={quest.id} onClick={() => postAnswer(id)}>
                                  {capitalizeFirstWord(quest.text)}
                                </AnswerButton>
                              </Grid>
                            ))}
                          </Grid>
                        </Box>
                        <Sum>
                          <span>Сумма: </span>
                          {prettyNum(pollSum())}
                        </Sum>
                      </div>
                    )}
                  </React.Fragment>
                ))}
            </>
          )}
        </PollContainer>
      ) : (
        ''
      )}
    </>
  );
};

export default Poll;
