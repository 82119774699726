import React from 'react';
import { Overlay } from 'components/Modal/styles';
import { Box } from '@material-ui/core';
import { GoldBgButton } from 'components/Header/styles';
import { ScrollBox } from 'components/Common/ScrollBox/styles';
import { Wrapper, Title } from './styles';

const PollOffer = ({ hide }) => {
  return (
    <Overlay>
      <Wrapper>
        <Title>Публичная оферта</Title>
        <ScrollBox height={'73vh'}>
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="flex-start" style={{ lineHeight: '2.5', fontSize: '90%' }}>
            <span style={{ textAlign: 'center', width: '100%' }}>
              <b>Cumpatator AGROVIDA IMPEX SRL</b>
            </span>
            <span style={{ textAlign: 'center', width: '100%' }}>ПРИКАЗ</span>
            <span style={{ textAlign: 'center', width: '100%' }}>
              «01» января 2023 года № 010123-1
            </span>
            <span style={{ textAlign: 'center', width: '100%' }}>
              Об утверждении публичной оферты
            </span>
            <span>
              В соответствии со статьей 435 и частью 2 статьи 437 Гражданского
              кодекса Российской Федерации.
            </span>
            <span> <b>приказываю:</b> </span>
            <span> 1. Утвердить предложение оферты в соответствии с Приложением № 1 к настоящему приказу. </span>
            <span>2. Определить срок действия оферты – 31.12.2023 года.</span>
            <span>
              3. Контроль за исполнением взятых обязательств в соответствии с
              офертой оставляю за собой.
            </span>
            <span style={{ textAlign: 'right', width: '100%' }}> Neaga Nicolai </span>

            <span>Приложение № 1</span>

            <span>к приказу Cumpatator AGROVIDA IMPEX SRL</span>

            <span>«01» января 2023 года № 010123-1</span>

            <span>ПУБЛИЧНАЯ ОФЕРТА</span>

            <span>
              Настоящее предложение является публичной офертой в соответствии со
              статьей 435 и частью 2 статьи 437 Гражданского кодекса Российской
              Федерации (далее – оферта).
            </span>

            <span> Настоящая оферта определяет взаимоотношения между Cumpatator
              AGROVIDA IMPEX SRL CIF/CNP/NIF: 26646022 , Numar de ordine in
              registrul comertului J4/234/2010 Localitate: SLANIC MOLDOVA
              Str.DOBRU nr.14Judetul:Bacau в лице Neaga Nicolai , именуемое в
              дальнейшем Организация, и физическим или юридическим лицом,
              принявшим оферту о заключении Договора, именуемый в дальнейшем
              Клиент. </span>
            <span> Организация выражает намерение заключить Договор с любым физическим или юридическим лицом и гарантирует исполнение взятых на себя в соответствии с настоящим Договором обязательств.
              Клиент. </span>
            <span> Клиент, от своего имени принимая данный Договор, выражает свое согласие с тем, что условия настоящего Договора не ущемляют законных прав Клиента. </span>
            <span>1. Термины и определения.</span>

            <span>
              1.1. Под понятием Клиент, следует понимать лицо, выразившее
              согласие на заключение Договора (далее по тексту – Клиент).
            </span>
            <span>
              1.2. Инфомат – электронно-вычисленная машина, иногда оснащенная
              дополнительными устройствами (далее по тексту – Инфомат).
            </span>
            <span>
              1.3. Электронный сервис – электронный сервис автоматизированной
              информационной системы «Социнформ» (собственная разработка
              Cumpatator AGROVIDA IMPEX SRL) интегрируемый по технологии API в
              любое программное обеспечение (далее по тексту – ЭС).
              Автоматизированная информационная система «Социнформ» не собирает
              и не обрабатывает персональные данные.
            </span>
            <span>
              1.4. Программное обеспечение – среда стороннего программного
              обеспечения, в которую по технологии API интегрируется электронный
              сервис автоматизированной информационной системы «Социнформ».
            </span>
            <span>
              1.5. Границы (рамки) работы ЭС начинаются с кнопки «Пройти опрос»,
              размещенной на главной странице Программного обеспечения, либо в
              ином разделе интерфейса Программного обеспечения и завершаются
              нажатием кнопки «Завершить опрос».
            </span>
            <span>2. Предмет Договора.</span>

            <span>
              2.1. Предметом данного Договора является предоставление Клиентом
              услуг Организации посредством предоставления своего мнения на
              интересующие Организацию вопросы в рамках проведения Организацией
              социологических опросов.
            </span>

            <span>3. Область действия Договора.</span>

            <span>
              3.1. Действие настоящего Договора распространяется на проводимые
              Организацией социологические опросы посредством Инфоматов.
            </span>

            <span>4. Факт заключения Договора.</span>

            <span>
              4.1. Фактом заключения настоящего Договора со стороны Клиента, то
              есть полным и безоговорочным принятием Клиентом условий настоящего
              Договора в соответствии с п. 1 ст. 433 и п. 3 ст. 438 Гражданского
              кодекса Российской Федерации, является нажатие кнопки «Принять
              условия оферты».
            </span>
            <span>5. Права и обязанности сторон.</span>

            <span>5.1. Клиент имеет право:</span>

            <span>
              5.1.1. Отказаться от предоставления своего мнения (ответов на
              вопросы). Данный отказ считается односторонним отказом от
              исполнения настоящего Договора.
            </span>

            <span>
              5.1.2. В любой момент обратиться к инструкции и ознакомиться с
              правилами проведения социологических опросов.
            </span>

            <span>
              5.1.3. В одностороннем порядке расторгнуть действие настоящего
              Договора без уведомления Организации.
            </span>

            <span>
              5.1.4. Обратиться по указанному в разделе «Инструкция» номеру
              телефона для связи с оператором службы технической поддержки и
              решением вопроса о передаче денежных средств Клиенту, в случае
              отсутствия в устройстве выдачи денежных средств Инфомата для
              оплаты услуг.
            </span>

            <span>
              5.1.5. Ответить не на все вопросы социологического опроса.
            </span>
            <span>5.2. Клиент обязуется:</span>

            <span>5.2.1. Соблюдать все положения настоящего Договора.</span>

            <span>
              5.2.2. Соблюдать и придерживаться положения о конфиденциальности,
              которое предусмотрено настоящим Договором, а также всех взятых на
              себя обязательств перед Организацией.
            </span>

            <span>
              5.2.3. Не предпринимать никаких действий, а также не использовать
              никакие приборы и(или) программные продукты, которые могут
              привести к сбою работы Программного обеспечения и(или) Инфомата и
              его отдельных компонентов.
            </span>

            <span>
              5.2.4. Не использовать в коммерческих и в иных целях, не
              копировать, не размножать, не распространять, не воспроизводить
              информацию, содержащуюся в Программном обеспечении.
            </span>
            <span>5.3. Организация вправе:</span>

            <span>
              5.3.1. На своё усмотрение демонстрировать Клиенту во время
              исполнения настоящего Договора рекламные сообщения, содержание
              которых не относится к предмету настоящего Договора.
            </span>

            <span>
              5.3.2. Без предупреждения Клиента вносить изменения в программное
              обеспечение.
            </span>

            <span>
              5.3.3. Производить видеозапись или сохранение снимков экрана в
              любом количестве во время действия настоящего Договора без
              уведомления Клиента.
            </span>

            <span>
              5.3.4. Пользоваться и распоряжаться любой информацией, полученной
              от Клиента во время действия настоящего Договора.
            </span>

            <span>
              5.3.5. Без уведомления Клиента блокировать доступ Клиента к
              программному обеспечению в случае, если Организации стали известны
              факты не соблюдения Клиентом пункта 5.2.3 настоящего Договора.
            </span>

            <span>
              5.3.6. Предоставлять Клиенту любые доказательства соблюдения
              Организацией положений настоящего Договора, включая
              экспортированные отчеты и записи из Информационной системы, а
              также видеозаписи или снимки экрана, фиксирующие процесс
              использования Клиентом программного обеспечения.
            </span>

            <span>
              5.3.6. Распространять или передавать третьим лицам любую
              полученную от Клиента информацию.
            </span>
            <span>6. Стоимость услуг и порядок расчетов.</span>

            <span>
              6.1. Стоимость услуг определяется в зависимости от сформированного
              программным обеспечением перечня вопросов.
            </span>

            <span>
              6.2. Организация с использованием информационной системы
              «Социнформ» составляет (формирует) список вопросов случайным
              образом. Стоимость предоставленного ответа на каждый вопрос
              определяется заведомо Организацией. Стоимость вопросов может
              варьироваться. Допустимы варианты, когда программное обеспечение
              формирует перечень, состоящий из вопросов, стоимость ответа на
              которые оценивается Организацией в ноль рублей.
            </span>

            <span>
              6.3. После предоставления Клиентом ответов на вопросы,
              сформированные Организацией, информационная система «Социнформ»
              суммирует номинальную стоимость за каждый ответ, предоставленный
              Клиентом. В случае, если суммарная стоимость предоставленных
              Клиентом ответов не равна нулю, то программное обеспечение после
              нажатия кнопки «выдать деньги» выплачивает Клиенту сумму, равную
              стоимости оказанных услуг.
            </span>

            <span>
              6.4. Обязательства Организации перед Клиентом считаются
              исполненными надлежащим образом и в полном объеме в случае выплаты
              Клиенту стоимости оказанных услуг либо
            </span>
            <span>7. Ответственность сторон.</span>

            <span>
              7.1. Клиент несет ответственность перед Организацией в случае
              порчи Инфомата и(или) отдельных его компонентов, а также за
              умышленную порчу программного обеспечения.
            </span>

            <span>
              7.2. Меры ответственности сторон, не предусмотренные настоящим
              Договором, применяются в соответствии с нормами действующего
              законодательства РФ.
            </span>

            <span>8. Ограничение ответственности.</span>

            <span>
              8.1. При любых обстоятельствах Организация не несет
              ответственности за достоверность информации, размещенной на любой
              из страниц или директорий программного обеспечения.
            </span>

            <span>
              8.2. Клиент освобождает Организацию от какой-либо ответственности
              за возможный материальный и(или) моральный ущерб, причиненный ему
              в результате использования программного обеспечения.
            </span>

            <span>
              8.3. Организация не несет ответственность за перебои в работе
              программного обеспечения.
            </span>
            <span>9. Срок действия Договора.</span>

            <span>
              9.1. Настоящий Договор вступает в силу с момента заключения и
              действует до выплаты Клиенту суммы, равной стоимости оказанных
              услуг, либо вывода на экран благодарственного сообщения.
            </span>

            <span>10. Заключительное положение.</span>

            <span>
              10.1. Все возникающие между сторонами споры и разногласия,
              касающиеся настоящего Договора, стороны разрешают путем
              переговоров.
            </span>

            <span style={{ textAlign: 'right', width: '100%' }}>
              Neaga Nicolai
            </span>
          </Box>
        </ScrollBox>
        <Box display="flex" justifyContent="center">
          <GoldBgButton style={{ margin: 5 }} onClick={hide}>
            Принять условия
          </GoldBgButton>
        </Box>
      </Wrapper>
    </Overlay>
  );
};

export default PollOffer;
