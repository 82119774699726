import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { PollBtn, Title, Record } from './styles';

import button from 'assets/images/violet-button.png';

import { recordsSelector, saveRecords } from '../../redux/recordsReducer';
import { Overlay, ModalWindow, ModalTop, ModalBody } from 'components/Modal/styles';
import { useNavigate } from 'react-router-dom';
import { Box } from '@material-ui/core';
import Preloader from 'components/Common/Preloader';
import { ScrollBox } from 'components/Common/ScrollBox/styles';

const Records = ({ hideRecords }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    items,
    savedRecord: { loading },
  } = useSelector(recordsSelector);

  React.useEffect(() => {
    // dispatch(getRecords());
    dispatch(saveRecords());
  }, [dispatch]);


  return (
    <>
      {
        <Overlay>
          <ModalWindow>
            <ModalTop />
            <ModalBody p={'6% 5%'}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Title>Рекорды</Title>
                <Box>
                  <ScrollBox height={'63vh'}>
                    {loading ? (
                      <Preloader />
                    ) : (
                      items && items.length &&
                      items.map((item, index) => (
                        <Record key={index}>
                          <span>{item.id}</span>
                          <span>{item.gambling_table_id}</span>
                          <span>{(item.balance_end / 100).toFixed(2)}</span>
                        </Record>
                      ))
                    )}
                  </ScrollBox>
                </Box>
                <PollBtn
                  img={button}
                  onClick={() => {
                    hideRecords();
                    navigate('/polls');
                  }}
                >
                  Пройти опрос
                </PollBtn>
              </Box>
            </ModalBody>
          </ModalWindow>
        </Overlay>
      }
    </>
  );
};

export default Records;
