import React from 'react';
import { Wrapper, Package } from './styles';
import { Box } from '@material-ui/core';

const GamePackages = ({ games, selectedProvider, setSelectedProvider, setCarouselValue }) => {
  const getGamePackages = (games) => {
    let providers = []
    games.forEach(item => {
      providers = [...providers, item.package]
    })
    return providers
  }

  return (
    <Wrapper style={{ maxHeight: "79vh", overflow: "auto" }}>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="flex-start">
        <Package onClick={() => { setCarouselValue(0); setSelectedProvider(null); }} className={!selectedProvider ? 'active' : ''}>
          Все игры
        </Package>
        {games &&
          getGamePackages(games).map((item, id) => <Package style={{ textAlign: "center" }} key={id} onClick={() => { setCarouselValue(0); setSelectedProvider(item.id); }} className={selectedProvider === item.id ? 'active' : ''}> {item.name} </Package>)}
      </Box>
    </Wrapper>
  );
};

export default GamePackages;
