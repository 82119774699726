import styled from 'styled-components';
import {down} from 'styled-breakpoints';
import play_button from '../../assets/images/play-button.png';

export const Wrapper = styled.div`
  width: 60%;
  margin: 0 auto;
  max-height: 100%;
  margin: auto;
  height: 100%;

  ${down('sm')} {
    width: 100%;
  }
`;

export const GamesSlide = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 68vh;
  width: 100%;
  overflow-y: scroll;
  scroll-behavior: smooth;
  ::-webkit-scrollbar {
    /* chrome based */
    width: 0px; /* ширина scrollbar'a */
    background: transparent; /* опционально */
  }
  -ms-overflow-style: none; /* IE 10+ */
  scrollbar-width: none; /* Firefox */
`;

export const Game = styled.div`
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  overflow: hidden;
  img {
    width: 95%;
    height: 95%;
  }

  :after {
    content: 'Играть';
    font-size: 90%;
    font-weight: bold;
    font-family: 'Raleway';
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #360A4E;
    position: absolute;
    transform: translate(-50%, -50%);
    border-radius: 6px;
    top: 50%;
    left: 50%;
    width: max-content;
    padding: 5px 10px;
    min-width: 80px;
    height: 20%;
    background: radial-gradient(
        66.6% 39.29% at 50% 50%,
        #fff 0%,
        rgba(255, 255, 255, 0) 74.48%
      ),
      #b8a772;
    opacity: 0;
    transition: 0.4s;
  }

  :hover:after {
    box-shadow: 0 0 10px 0 #b8a772;
    opacity: 1;
  }

  ${down('md')} {
    :after {
      font-size: 60%;
    }
  }
`;

export const PlayBtn = styled.button`
  cursor: pointer;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  text-decoration: none;
  margin: auto;
  display: block;
  width: 135px;
  height: 50px;
  border: none;
  background-color: transparent;
  background-image: ${`url(${play_button})`};
  background-position: top center;
  background-repeat: no-repeat;
  font-family: revert;
  font-size: 70%;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  line-height: 50px;
  color: #fff;

  &:hover {
    filter: brightness(1.3);
    background-position: bottom center;
  }
`;
