import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  padding: 24px 20px 20px 20px;
  background: linear-gradient(180deg, #14041d 0%, #370b50 50%, #14041d 100%),
    #370b50;
  position: relative;

  .denom {
    cursor: pointer;
    border-radius: 6px;
    -webkit-appearance: none;
    width: 40%;
    text-align-last: center;
    border: 1px solid #b8a772;
    color: #b8a772;
    font-size: 100%;
    font-family: 'Raleway';
    line-height: 23px;
    margin-left: 5px;
    background: linear-gradient(180deg, #14041d 0%, #370b50 50%, #14041d 100%),
      #370b50;
    text-align: center;
    position: relative;
    padding: 5px 15px;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, #0a0410 0%, rgba(10, 4, 16, 0) 21.3%),
      linear-gradient(270deg, #0a0410 0%, rgba(10, 4, 16, 0) 20.77%),
      linear-gradient(
        270deg,
        rgba(255, 255, 255, 0) 27.47%,
        #fff 48.58%,
        rgba(255, 255, 255, 0) 69.27%
      ),
      #b8a772;
  }
`;

export const Title = styled.div`
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: #b8a772;
  font-weight: 300;
`;

export const Balance = styled.div`
  margin: 22px 0;
  padding: 5px 10px;
  font-size: 165%;
  line-height: 38px;
  text-align: center;
  color: #b8a772;
  border-radius: 6px;
  width: 100%;
  font-family: 'Raleway';
  font-weight: normal;
  background: linear-gradient(#b8a772 33.25%, #eaeaea 46.88%, #b8a772 67.41%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border-radius: 6px;
  border: 1px solid #b8a772;
`;
