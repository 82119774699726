export const API = 'https://sport.millenium.link';
export const AUTH_API = process.env.REACT_APP_URL_API;
export const faces = [
    process.env.REACT_APP_TEMPLATE_URL_GOLD ?? null,
    process.env.REACT_APP_TEMPLATE_URL_RED ?? null,
    null,
    process.env.REACT_APP_TEMPLATE_URL_LOTO ?? null,
    process.env.REACT_APP_TEMPLATE_URL_CES ?? null,
    process.env.REACT_APP_TEMPLATE_URL_ARENA ?? null,
    process.env.REACT_APP_TEMPLATE_URL_TADJ ?? null,
    process.env.REACT_APP_TEMPLATE_URL_KENO ?? null,
];
export const DEBUG = +process.env.REACT_APP_DEBUG;
export const LOGIN_PAGE = process.env.REACT_APP_URL_LOGIN;