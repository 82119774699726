import {createSlice} from '@reduxjs/toolkit';
import {records} from '../services';

export const slice = createSlice({
  name: 'records',
  initialState: {
    items: [],
    loading: false,
    error: null,
    status: null,
    savedRecord: {
      data: [],
      loading: false,
      error: null,
    },
  },
  reducers: {
    getRecordsIsLoading: (state) => {
      state.loading = true;
    },
    getRecordsSuccess: (state, {payload}) => {
      state.items = payload;
      state.loading = false;
      state.error = null;
    },
    getRecordsFailure: (state, {payload}) => {
      state.loading = false;
      state.error = payload;
    },
    saveRecordsIsLoading: (state) => {
      state.savedRecord.loading = true;
    },
    saveRecordsSuccess: (state, {payload}) => {
      state.savedRecord.data = payload;
      state.savedRecord.loading = false;
      state.savedRecord.error = null;
    },
    saveRecordsFailure: (state, {payload}) => {
      state.savedRecord.loading = false;
      state.savedRecord.error = payload;
    },
    setRecordsStatus: (state, {payload}) => {
      state.status = payload;
    },
  },
});

export function getRecords(gameId) {
  return async (dispatch) => {
    dispatch(getRecordsIsLoading());

    try {
      const response = await records.fetchRecords(gameId);
      dispatch(getRecordsSuccess(response.data));
      dispatch(setRecordsStatus(response.code))
    } catch (error) {
      dispatch(getRecordsFailure(error.message));
      dispatch(setRecordsStatus(error.code))
    }
  };
}

export function saveRecords() {
  return async (dispatch) => {
    dispatch(saveRecordsIsLoading());

    try {
      const response = await records.saveRecord();
      dispatch(saveRecordsSuccess(response.data.data));
      dispatch(getRecordsSuccess(response.data.value))
    } catch (error) {
      dispatch(saveRecordsFailure(error.message));
    }
  };
}

export const {
  getRecordsIsLoading,
  getRecordsSuccess,
  getRecordsFailure,
  saveRecordsIsLoading,
  saveRecordsSuccess,
  saveRecordsFailure,
  setRecordsStatus
} = slice.actions;

export const recordsSelector = (state) => state.records;

export default slice.reducer;
