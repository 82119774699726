import { Box, makeStyles, Menu, MenuItem } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from 'redux/authReducer';
import { changeDenomination } from 'redux/denominationReducer';
import { prettyNum } from 'utils/helpers';
import { Wrapper, Title, Balance } from './styles';

const useStyles = makeStyles((theme) => ({
  item: {
    '& .MuiListItem-root': {
      fontSize: '100%',
      color: ' #B8A772',
      fontFamily: 'Raleway',
      background:
        'linear-gradient(180deg,#14041d 0%,#370b50 50%,#14041d 100%), #370b50',
    },
    '& .MuiList-root': {
      background: '#14041d',
      border: '1px solid #b8a772',
      borderRadius: '6px'
    },
  },
}));

const UserPoints = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { authInfo: { info } } = useSelector(authSelector);

  const balance = info && info.balance;
  const denomination = info && info.denomination;
  const denominations = info && info.denominations;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  return (
    <Wrapper>
      <Title>Очки</Title>
      <Balance>{Math.floor(balance / 100)}</Balance>
      <Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap">
        <span style={{ fontWeight: 300, color: '#B8A772' }}>Деноминация </span>
        <Box className="denom" display="flex" justifyContent="space-around" alignItems="center" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
          {denomination ? prettyNum(denomination) : ''} <ArrowDropDown />
        </Box>
        <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose} className={classes.item}>
          {denominations && denominations.map((option) =>
            <MenuItem key={option} onClick={() => { handleClose(); dispatch(changeDenomination({ denomination: option })); }}>
              {prettyNum(option)}
            </MenuItem>)}
        </Menu>
        {/* <NativeSelect
        
          disableUnderline
          value={denomination ? denomination : ''}
          onChange={(e) =>
            {}
          }
          name="age"
          inputProps={{
            style: {
              color: '#fff',
              fontFamily: 'Colus',
              outline: 'none',
            },
          }}
          style={{outline: 'none'}}
        >
          {denominations &&
            denominations.map((option) => (
              <option
                key={option}
                value={option}
                style={{fontFamily: 'Raleway'}}
              >
                {prettyNum(option)}
              </option>
            ))}
        </NativeSelect> */}
      </Box>
    </Wrapper>
  );
};

export default UserPoints;
